.companyNameLogo {
    margin: 0;
    font-size: 24px;
    white-space: normal;
    height: auto;
}

/* .MuiButtonBase-root{
    height: auto;
    padding-top: 10px;
} */

.DropZone-Card {
    border: "1px solid #D0D0D0";
    margin-top: "20px"
}

.company-details-button {
    background-color: '#E6F7FF';
    color: "#1890FF";
    border: 1px solid #1890FF;
}
.page-header {
    font-size: 16px;
    font-weight: 600;
    padding-left: 20px;
    padding-top: 20px;
}
.tableSearchBox .MuiSvgIcon-root {
    height: .8em;
    width: .8em;
    color: currentColor
}