

.view-sent-text{
    width: 200px;
    text-align: center;
    color: #32CD32;
}

.view-unsent-text{
    width: 200px;
    text-align: center;
    color: #FF0000;
}

.view-table-heading{
    padding-bottom: 20px;
}

.header-box{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

/* .MuiIconButton-root{
        padding-top: 0px;
        height: 30px
} */

.modal-buttons{
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
}