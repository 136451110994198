.status-card-heading{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
}
.status-card-text{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    color: #939598;
}

.comments-heading{
    font-style: italic;
    font-size: 16px;
    color: #212121;
}

.table-heading{
    font-size: 15px;
    font-weight: 700;
}

.divider{
    margin-top: 15px;
    margin-bottom: 15px;
}

.urlCard{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px;
    border: 0.1px solid #D0D0D0;
    margin: 5px;
}

.no-files-typo{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
