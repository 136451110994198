.stack{
    display: inline-flex;
}

.align-field{
    display: flex;
    align-items: center;
}

.time-sheet-text{
    display: flex;
    justify-content: center;
    font-size: 20px;
}
.time-sheet-check{
    display: flex;
    justify-content: center;
    font-size: 20px;
    flex-direction: inherit;
    margin-top: 5px;
}