input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.cashD-card {
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.table-style{
    padding: 20px;
}