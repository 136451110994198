.top-box{
    display: flex;
    align-items: center;
}
.lender-heading{
    display: flex;
    justify-content: center;
}

.lender-heading{
    font-size: 20px;
    font-weight: 700;
    color: #000000;
    text-align: center;
}

.lender-typo{
    font-size: 20px;
    font-weight: 700;
    color: #939598;
    text-align: center; 
}

.add-button{
    text-align: center;
    margin-top: 5px;
}

.add-icon{
    cursor: pointer;
    color: #939598;
}


.add-icon:hover{
    cursor: pointer;
    color: dodgerblue;
}

.confirmation_modal{
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
}
