.company_name{
    margin-left: 10px;
    width: 700px;
}

.searchbar{
    width: 200px;
}

.searchBox{
    margin-left: 10px;
    width: 500px;
}

.search-icon{
    color: #262620;
}

.MuiAutocomplete-noOptions{
    display: none;
}

.company-name-text{
    font-size: 16px;
    font-weight: 600;
}